import React, { useEffect, useRef, useState } from "react";

// Next
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// React Icons
import { IoMenu } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa6";
import { MdHelpOutline } from "react-icons/md";

// Components
import { Button } from "@/components/common/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/common/Collapsible";
import Logo from "../../common/Logo";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../common/Tooltip";
import HelpModal from "@/containers/Modals/HelpModal";

// Utils
import { getRoleAccessbility } from "@/utils/functions/getRoleAccessbility";
import { getFQCN } from "@/utils/functions/fqcn";
import { useAppSelector } from "@/utils/hooks/store";
import { cn } from "@/utils/functions/cn";

// Site data
import { SidebarMenus } from "@/sitedata/SidebarMenu";

const fqcn_bui = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

type SidebarMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isOpen, setIsOpen }) => {
  const [helpModal, setHelpModal] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const submenuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const locale = useLocale();
  const t = useTranslations("SidebarMenu");
  const pathname = usePathname();

  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    SidebarMenus.forEach((item) => {
      if (item.subMenus) {
        item.subMenus.forEach((submenu) => {
          if (pathname.includes(`/${locale}${submenu.link}`)) {
            setOpenSubmenu(item.title);
          }
        });
      }
    });
  }, [pathname, locale]);

  const generateLink = (
    link: string,
    title: string,
    icon: React.ReactNode,
    subMenu?: boolean
  ) => {
    return (
      <Link
        id={getFQCN(
          fqcn_bui,
          "menu",
          `${title}${subMenu ? "-submenu" : ""}-link`
        )}
        href={link?.includes("https") ? link : `/${locale}${link}`}
        target={link?.includes("https") ? "_blank" : "_self"}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger
              className={`flex w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white ${
                pathname.includes(`/${locale}${link}`)
                  ? subMenu
                    ? "text-phpc-blue-dark dark:text-phpc-blue"
                    : "bg-phpc-blue-dark dark:bg-phpc-blue"
                  : `${
                      subMenu
                        ? "hover:text-gray-200"
                        : "hover:text-phpc-blue-dark"
                    } dark:hover:text-phpc-blue`
              }`}
            >
              <span className="flex items-center justify-center h-6">
                {icon}
              </span>
              <CollapsibleContent>
                <p className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {t(title)}
                </p>
              </CollapsibleContent>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t(title)}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </Link>
    );
  };

  return (
    <>
      <Collapsible
        open={isOpen}
        onOpenChange={setIsOpen}
        className={`bg-phpc-blue dark:bg-phpc-blue-dark transition-transform duration-500 fixed md:relative min-h-screen z-[60] flex flex-col ${
          !isOpen
            ? "translate-x-[-100%] md:translate-x-0 shadow-xl"
            : "translate-x-0"
        }`}
      >
        <div className="flex flex-col flex-grow print:hidden h-screen relative">
          <article className="sticky top-0 p-2 flex flex-col h-screen overflow-y-auto">
            <div className="flex mb-3">
              <CollapsibleContent>
                <Logo
                  fqcn_bui={{
                    Bundle: "menuBundle",
                    Unit: "logo",
                    Interface: "menu",
                  }}
                />
              </CollapsibleContent>
              <CollapsibleTrigger asChild>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        id={getFQCN(fqcn_bui, "menu", "collapsible-button")}
                        className="w-full m-auto mt-4 p-0 bg-transparent hover:bg-transparent"
                        onClick={() => {
                          setIsOpen(!isOpen);
                          localStorage.setItem(
                            "menuState",
                            JSON.stringify(!isOpen)
                          );
                        }}
                      >
                        <IoMenu
                          id={getFQCN(fqcn_bui, "menu", "collapsible-icon")}
                          className="w-7 h-7 text-white"
                        />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{isOpen ? t("Close") : t("Open")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </CollapsibleTrigger>
            </div>

            {SidebarMenus?.map((item, _id) => (
              <div key={_id}>
                {item?.subMenus ? (
                  <div className="border-b border-gray-300/30">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger
                          className={`flex w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white
                              ${
                                pathname.includes(`/${locale}${item?.link}`)
                                  ? "bg-phpc-blue-dark dark:bg-phpc-blue"
                                  : "hover:text-phpc-blue-dark dark:hover:text-phpc-blue"
                              }`}
                          onClick={() =>
                            openSubmenu === item?.title
                              ? setOpenSubmenu(null)
                              : setOpenSubmenu(item?.title)
                          }
                        >
                          <span className="flex items-center justify-center h-6">
                            {item?.icon}
                          </span>
                          <CollapsibleContent className="flex-1">
                            <div className="flex items-center w-full gap-4 justify-between">
                              <p className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                                {t(item?.title)}
                              </p>
                              <span className="flex items-center justify-center h-6">
                                <FaChevronDown className="h-4 w-4 mx-auto" />
                              </span>
                            </div>
                          </CollapsibleContent>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t(item?.title)}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>

                    <div
                      ref={(el) => {
                        submenuRefs.current[item.title] = el;
                      }}
                      className={`overflow-hidden transition-all duration-500 ease-in-out bg-phpc-blue-dark dark:bg-dark-mode -mx-2 px-2`}
                      style={{
                        height:
                          openSubmenu === item?.title
                            ? `${
                                submenuRefs.current[item.title]?.scrollHeight
                              }px`
                            : "0px",
                      }}
                    >
                      {item?.subMenus?.map((submenu, index) => (
                        <div
                          key={index}
                          className={
                            index === item?.subMenus?.length - 1 ? "pb-4" : ""
                          }
                        >
                          {generateLink(
                            submenu.link,
                            submenu.title,
                            submenu.icon,
                            true
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div key={_id} className="border-b border-gray-300/30">
                    {generateLink(item.link, item.title, item.icon, false)}
                  </div>
                )}
              </div>
            ))}

            <div className="mt-auto mx-auto mb-10">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger
                    className="flex items-center w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white hover:text-phpc-blue-dark dark:hover:text-phpc-blue"
                    onClick={() => setHelpModal(true)}
                  >
                    <span className="flex items-center justify-center">
                      <MdHelpOutline className="h-5 w-5 mx-auto" />
                    </span>
                    <CollapsibleContent>
                      <p className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {t("Help")}
                      </p>
                    </CollapsibleContent>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("Press Alt + ?")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </article>
        </div>
      </Collapsible>

      <div
        className={`fixed inset-0 z-[59] bg-black/50 transition-opacity duration-500 md:hidden ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>

      {helpModal && (
        <HelpModal isOpen={helpModal} onClose={() => setHelpModal(false)} />
      )}
    </>
  );
};

export default SidebarMenu;
