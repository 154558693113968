import { useState, useEffect } from "react";

// Next Intl
import { useLocale } from "next-intl";

// Sentry
import * as Sentry from "@sentry/nextjs";

// Radix UI
import * as Dialog from "@radix-ui/react-dialog";

// React Icons
import { IoCloseOutline } from "react-icons/io5";

// HTML2Canvas
import html2canvas from "html2canvas";

// Components
import PageLoader from "@/components/common/PageLoader";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

type FeedbackModalProps = {
  onClose: (open: boolean) => void;
  isOpen: boolean;
};

const fqcn_bui: IFQCN_BUI = {
  Bundle: "homeBundle",
  Unit: "feedback",
  Interface: "feedback",
};

const FEEDBACK_URL = process.env.NEXT_PUBLIC_TICKET_FEEDBACK || "";

const FeedbackModal: React.FC<FeedbackModalProps> = ({ onClose, isOpen }) => {
  const locale = useLocale();

  const [pageUrl, setPageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [screenshotData, setScreenshotData] = useState<string>("");

  const handleLoad = () => {
    setIsLoading(false);
  };

  const captureScreenshot = () => {
    html2canvas(document.body, {
      logging: true,
      onclone: (doc) => {
        const formElement = doc.getElementById("feedback__form");
        if (formElement) {
          formElement.style.visibility = "hidden";
        }
      },
    })
      .then((canvas) => {
        const screenshotDataUrl = canvas.toDataURL("image/png");
        setScreenshotData(screenshotDataUrl);
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    if (screenshotData) {
      const iframe = document.getElementById(
        "feedbackIframe"
      ) as HTMLIFrameElement;

      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage(
          { screenshot: screenshotData, url: pageUrl },
          FEEDBACK_URL
        );
      }
    }
  }, [screenshotData, pageUrl]);

  useEffect(() => {
    setPageUrl(window.location.href);
    captureScreenshot();
  }, []);

  return (
    <Dialog.Root modal={true} open={isOpen} onOpenChange={onClose}>
      <div id="feedbackIframe">
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay width-full h-full fixed inset-0 bg-black opacity-40 animate-overlayShow z-[70]" />
          <Dialog.Content
            className="DialogContent overflow-hidden rounded-lg fixed top-1/2 left-1/2 max-w-7xl max-h-[87vh] p-3 md:p-6 h-full z-[70] bg-white focus:outline-none shadow-2xl -translate-x-1/2 -translate-y-1/2 animate-contentShow"
            style={{
              width: "calc(100% - 40px)",
            }}
          >
            <div className="IconButton w-full relative h-6">
              <Dialog.Close asChild>
                <button
                  id={getFQCN(fqcn_bui, "button-close-feedback")}
                  className="IconButton text-black h-6 right-0 absolute top-0"
                  aria-label="Close"
                >
                  <IoCloseOutline className="w-6 h-6" />
                </button>
              </Dialog.Close>
            </div>
            {isLoading && <PageLoader />}
            <div className="w-full h-full">
              <iframe
                src={`${FEEDBACK_URL}/${locale}/feedback`}
                onLoad={handleLoad}
                className="w-full h-full"
              />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </div>
    </Dialog.Root>
  );
};

export default FeedbackModal;
