"use client";

// React
import React, { useState, FC, useEffect } from "react";

// Next.js
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// React Icons
import { HiLogin, HiLogout } from "react-icons/hi";
import { IoMenu } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";

// JS Cookie
import cookie from "js-cookie";

// Components
import { Button } from "@/components/common/Button";
import ModeToggle from "@/components/common/ModeToggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/common/Tooltip";
import Logo from "@/components/common/Logo";
import { Skeleton } from "../../common/Skeleton";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../common/Breadcrumb";
import { Popover, PopoverContent, PopoverTrigger } from "../../common/Popover";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";
import { useDetailedShowContext } from "@/contexts/DetailShowContext";

type Props = {
  fqcn_bui: IFQCN_BUI;
  sidebarMenu?: boolean;
  setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  itemData?: Record<string, string>;
};

const capitalizeFirst = (text: string) => {
  if (!text) return "";
  return text
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const Navbar: FC<Props> = ({
  fqcn_bui,
  sidebarMenu = false,
  setSidebarOpen,
  itemData = {},
}) => {
  const [username, setUsername] = useState("");
  const [breadcrumb, setBreadcrumb] = useState<
    { label: string; href: string }[]
  >([]);
  const [breadcrumbLoading, setBreadcrumbLoading] = useState(true);
  const [tokenLoading, setTokenLoading] = useState(true);

  const { data } = useDetailedShowContext();

  const t = useTranslations("Navbar");
  const locale = useLocale();

  const handleLogout = () => {
    cookie.remove("currentUser");
    cookie.remove("roles");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    const fetchBreadcrumb = () => {
      if (typeof window !== "undefined") {
        const pathSegments = window.location.pathname
          .split("/")
          .filter((item) => item !== "" && item !== "en" && item !== "fr");

        let cumulativePath = `/${locale}`;
        const updatedBreadcrumb = pathSegments.map((segment, index) => {
          cumulativePath += `/${segment}`;
          if (
            data?.id?.toString() === segment ||
            itemData?.id?.toString() === segment ||
            !isNaN(Number(segment))
          ) {
            return {
              label:
                typeof data?.toString === "string"
                  ? capitalizeFirst(data.toString)
                  : typeof itemData?.toString === "string"
                  ? capitalizeFirst(itemData?.["toString"])
                  : capitalizeFirst(segment),
              href: cumulativePath,
            };
          }
          return {
            label: t(capitalizeFirst(segment)),
            href: cumulativePath,
          };
        });

        setBreadcrumb([...updatedBreadcrumb]);

        if (pathSegments.length >= 2 && !data && !itemData) {
          setBreadcrumbLoading(true);
          const timer = setTimeout(() => {
            setBreadcrumbLoading(false);
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          setBreadcrumbLoading(false);
        }
      }
    };

    fetchBreadcrumb();
  }, [data, itemData, t, locale]);

  const token = cookie.get("currentUser");
  useEffect(() => {
    const fetchToken = () => {
      if (token) {
        setTokenLoading(true);
      }
      setTokenLoading(false);
    };

    if (typeof window !== "undefined") {
      const username = localStorage.getItem("username");
      setUsername(username || "");
    }

    fetchToken();
  }, []);

  return (
    <article className="w-full p-3 flex justify-between items-center gap-2 md:gap-0 print:hidden bg-gray-300 dark:bg-slate-700 h-[94px]">
      {sidebarMenu ? (
        <div className="w-full flex items-center gap-1">
          <Button
            id={getFQCN(fqcn_bui, "menu", "collapsible-button")}
            className="w-7 h-7 p-0 bg-transparent hover:bg-transparent relative md:hidden"
            onClick={() => {
              setSidebarOpen && setSidebarOpen((prev) => !prev);
              localStorage.setItem("menuState", JSON.stringify(sidebarMenu));
            }}
          >
            <IoMenu className="w-7 h-7 text-white" />
          </Button>

          {breadcrumbLoading ? (
            <Skeleton className="h-10 w-full bg-gray-50 max-w-40" />
          ) : (
            <Breadcrumb
              id={getFQCN(fqcn_bui, "breadcrumb")}
              className="md:w-full md:ml-5 print:hidden"
            >
              <BreadcrumbList id={getFQCN(fqcn_bui, "list-breadcrumb")}>
                <BreadcrumbItem>
                  <Link href={`/${locale}`} prefetch={false}>
                    {t("Home")}
                  </Link>
                </BreadcrumbItem>
                {breadcrumb.map((item, index) => (
                  <React.Fragment key={index}>
                    <BreadcrumbSeparator>/</BreadcrumbSeparator>
                    <BreadcrumbItem>
                      {index === breadcrumb.length - 1 ? (
                        <BreadcrumbPage
                          className="capitalize text-white"
                          aria-current="page"
                        >
                          {item.label}
                        </BreadcrumbPage>
                      ) : (
                        <Link href={item.href} prefetch={false}>
                          {item.label}
                        </Link>
                      )}
                    </BreadcrumbItem>
                  </React.Fragment>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          )}
        </div>
      ) : (
        <Logo
          fqcn_bui={{
            Bundle: "homeBundle",
            Unit: "logo",
            Interface: "landing",
          }}
        />
      )}

      <div className="flex gap-4 w-fit md:w-full justify-end items-center">
        {username && token && (
          <h4 className="hidden md:block">{t("Welcome") + " " + username}</h4>
        )}

        {tokenLoading ? (
          <Skeleton className="h-10 w-full  bg-gray-50 max-w-32" />
        ) : (
          token && (
            <div>
              <div className="hidden md:block">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        id={getFQCN(fqcn_bui, "logout-button")}
                        name="logout-button"
                        className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue"
                        onClick={handleLogout}
                      >
                        <HiLogin className="w-5 h-5 text-white print:text-black" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("Logout")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="block md:hidden">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className="border-gray-300"
                      size="icon"
                      id={getFQCN(fqcn_bui, "mode-toggle")}
                    >
                      <FaRegUser className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    align="end"
                    className="flex flex-col gap-3 w-fit"
                  >
                    <h4 className="text-center">
                      {t("Welcome") + " " + username}
                    </h4>
                    <Button
                      id={getFQCN(fqcn_bui, "logout-button")}
                      name="logout-button"
                      className="h-8 bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue w-full text-white print:text-black"
                      onClick={handleLogout}
                    >
                      <HiLogout className="w-5 h-5" />
                      <p>{t("Logout")}</p>
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          )
        )}

        <ModeToggle
          fqcn_bui={{
            Bundle: "navbarBundle",
            Unit: "navBar",
            Interface: "modeToggle",
          }}
        />
      </div>
    </article>
  );
};

export default Navbar;
