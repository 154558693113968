export const TenantWhitelist: Array<string> = [
  "demo-ecs",
  "demo",
  "staging",
  "repere",
  "picard",
  "phpc",
  "demo-sf5",
  "nt",
];

export const TenantIgnorelist: Array<string> = ["www"];
