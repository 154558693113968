import React, { useEffect, useState } from "react";

// Next
import { usePathname, useRouter } from "next/navigation";
import { useLocale } from "next-intl";

// React Hot Toast
import { Toaster } from "react-hot-toast";

// Hotkeys
import hotkeys from "hotkeys-js";

// QR Code Generation
import QRCode from "react-qr-code";

// Moment
import moment from "moment";

// Components
import Navbar from "./Navbar";
import Footer from "./Footer";
import SidebarMenu from "./SidebarMenu";
import HelpModal from "@/containers/Modals/HelpModal";

// Utils
import { useAppDispatch } from "@/utils/hooks/store";
import { setUser } from "@/store/slices/authSlice";

type LayoutProps = {
  children: React.ReactNode;
  sidebarMenu?: boolean;
  itemData?: Record<string, string>;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  sidebarMenu = false,
  itemData = {},
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const dispatch = useAppDispatch();

  const router = useRouter();
  const locale = useLocale();
  const pathname = usePathname();

  useEffect(() => {
    const menuState = localStorage.getItem("menuState");

    setCurrentUrl(window.location.href);

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        if (menuState !== undefined && menuState !== null) {
          JSON.parse(menuState);
          setSidebarOpen(JSON.parse(menuState));
        } else {
          setSidebarOpen(true);
        }
      } else {
        setSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const username = localStorage.getItem("username") || "";
    const roles = JSON.parse(localStorage.getItem("roles") || "[]");
    dispatch(
      setUser({
        username,
        roles,
      })
    );
  }, []);

  useEffect(() => {
    hotkeys("alt+h", (event) => {
      event.preventDefault();
      router.push(`/${locale}`);
    });

    hotkeys("alt+backspace", (event) => {
      event.preventDefault();
      router.back();
    });

    hotkeys("alt+shift+n", (event) => {
      event.preventDefault();
      router.forward();
    });

    hotkeys("alt+?,alt+/", (event) => {
      event.preventDefault();
      setHelpModalOpen(true);
    });

    return () => {
      hotkeys.unbind("alt+h");
      hotkeys.unbind("alt+backspace");
      hotkeys.unbind("alt+shift+n");
      hotkeys.unbind("alt+?,alt+/");
    };
  }, [router, locale]);

  return (
    <main className="flex min-h-screen h-full max-w-screen w-full dark:bg-dark-mode">
      {sidebarMenu && (
        <SidebarMenu isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      )}

      {helpModalOpen && (
        <HelpModal
          isOpen={helpModalOpen}
          onClose={() => setHelpModalOpen(false)}
        />
      )}

      <Toaster />

      <section className="w-full">
        <Navbar
          fqcn_bui={{
            Bundle: "layoutBundle",
            Unit: "navbar",
            Interface: "layout",
          }}
          sidebarMenu={sidebarMenu}
          setSidebarOpen={setSidebarOpen}
          itemData={itemData}
        />

        <div
          className={`page-height relative mx-auto ${
            !pathname?.includes("/login")
              ? sidebarMenu && sidebarOpen
                ? "md:max-w-[calc(100vw-220px)]"
                : "md:max-w-[calc(100vw-80px)]"
              : ""
          }`}
        >
          {children}
        </div>

        <Footer
          fqcn_bui={{
            Bundle: "layoutBundle",
            Unit: "navbar",
            Interface: "layout",
          }}
        />
      </section>

      <div className="hidden print:grid print:grid-cols-3 print:fixed print:bottom-0 print:left-0 print:right-0 print:text-xs print:items-end print:gap-2 print:max-w-full">
        <div className="truncate">
          <span className="font-bold">Date: </span>
          {moment().format("DD/MM/YYYY HH:mm")}
        </div>

        <div className="truncate text-center">
          <span className="font-bold">URL: </span>
          <span className="break-all">{currentUrl}</span>
        </div>

        <div className="flex flex-col items-end">
          <span className="text-[0.6rem] mb-1">Scan to view page:</span>
          <QRCode value={currentUrl} size={64} className="self-end" />
        </div>
      </div>
    </main>
  );
};

export default Layout;
