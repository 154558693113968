"use client";

import { FC } from "react";

// Next
import { useTheme } from "next-themes";
import { useTranslations } from "next-intl";

// React Icons
import { IoSunnyOutline, IoMoonOutline } from "react-icons/io5";

// Components
import { Button } from "@/components/common/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/common/DropdownMenu";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const ModeToggle: FC<Props> = ({ fqcn_bui }) => {
  const { setTheme, theme } = useTheme();
  const t = useTranslations("ModeToggle");

  return (
    <div className="print:hidden" id={getFQCN(fqcn_bui, "mode-toogle")}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="border-gray-300"
            size="icon"
            id={getFQCN(fqcn_bui, "mode-toggle")}
          >
            <IoSunnyOutline className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <IoMoonOutline className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "light-toggle")}
            onClick={() => setTheme("light")}
            aria-selected={theme === "light"}
            className={
              theme === "light" ? "!bg-slate-700/40 !dark:bg-slate-700" : ""
            }
          >
            {t("Light")}
          </DropdownMenuItem>
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "dark-toggle")}
            onClick={() => setTheme("dark")}
            aria-selected={theme === "dark"}
            className={
              theme === "dark" ? "!bg-slate-700/40 !dark:bg-slate-700" : ""
            }
          >
            {t("Dark")}
          </DropdownMenuItem>
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "system-toggle")}
            onClick={() => setTheme("system")}
            aria-selected={theme === "system"}
            className={
              theme === "system" ? "!bg-slate-700/40 !dark:bg-slate-700" : ""
            }
          >
            {t("System")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ModeToggle;
